<template>
  <vxe-toolbar ref="toolbarRef" print export>
    <template #buttons>
      <el-row :gutter="20">
        <el-col :span="2">
          <vxe-button icon="vxe-icon-add" size="mini" status="primary" content="新增"
            @click="centerDialogVisible = true"></vxe-button>
        </el-col>
        <el-col :span="8">
          <el-date-picker v-model="searchData.timeNode" type="daterange" range-separator="To" start-placeholder="开始时间"
            end-placeholder="结束时间" size="small" value-format="YYYY-MM-DD" />
        </el-col>
        <el-col :span="5">
          <el-select v-model="searchData.userid" placeholder="选择人员" size="small">
            <el-option key="0" label="全部人员" value="0" />
            <el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id" />
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select v-model="searchData.fishtype" placeholder="选择鱼类" size="small">
            <el-option key="0" label="全部" value="0" />
            <el-option v-for="typeinfo in typeinfos" :key="typeinfo.id" :label="typeinfo.name" :value="typeinfo.id" />
        
          </el-select>
        </el-col>
        <el-col :span="2">
          <vxe-button status="success" size="mini" content="查询" icon="vxe-icon-search"
            @click="SeachDataHandle()"></vxe-button>
        </el-col>
        <el-col :span="2">
          <vxe-button status="warning" size="mini" content="导出EXCEL全部数据" icon="vxe-icon-file-excel"
            @click="DownloadExcel()"></vxe-button>
        </el-col>

        <!--  <vxe-button content="打印勾选行" @click="printSelectEvent"></vxe-button> -->

      </el-row>
    </template>
  </vxe-toolbar>
  <div style="height: calc(100vh - 210px)">
    <vxe-table :data="tableData" ref="tableRef" size="small" :row-config="{ isCurrent: true, isHover: true }"
      :print-config="{}" height="100%" :footer-method="footerMethod" show-footer>
      <!-- 表格列配置 -->
      <vxe-column type="seq" width="50"></vxe-column>
      <vxe-column field="name" title="名称"></vxe-column>
      <vxe-column field="spec" title="单位"></vxe-column>
      <vxe-column field="num" title="数量"></vxe-column>
      <vxe-column field="price" title="单价(¥)"></vxe-column>
      <vxe-column field="userid" title="捕捞人员"></vxe-column>
      <vxe-column field="date" title="录入日期"  formatter="formatDate"></vxe-column>
      <vxe-column field="reward" title="当前页总提成(¥)"></vxe-column>
      <vxe-column field="totalprice" title="当前页总价(¥)"></vxe-column>
    </vxe-table>
  </div>

  <vxe-pager background v-model:current-page="pageVO2.currentPage" v-model:page-size="pageVO2.pageSize"
    @page-change="pagechangeHandle()" :total="pageVO2.total"
    :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'FullJump', 'Total']">
  </vxe-pager>

  <!--这里是新增弹窗-->
  <el-dialog v-model="centerDialogVisible" title="新增数据" width="30%" :destroy-on-close="true" :close-on-click-modal="false"
    @close="closeLogDialog">
    <div style="display: flex; justify-content: center; align-items: center;">
    <el-form label-width="80px" :model="formLabelAlign"  size="small" label-position="right" >
      <el-row>
        <el-col :span="24">
          <el-form-item label="类型">
            <el-select v-model="formLabelAlign.type_id" placeholder="选择品种" size="small">
              <el-option v-for="typeinfo in typeinfos" :key="typeinfo.id" :label="typeinfo.name" :value="typeinfo.id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>


      <el-row>
        <el-col :span="24">
          <el-form-item label="作业人员">
            <el-select v-model="formLabelAlign.userid" placeholder="选择人员" size="small">
            <el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id" />
          </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      


      <el-row>
        <el-col :span="24">
          <el-form-item label="单价">
            <el-input-number v-model="formLabelAlign.price" :precision="2" :max="999999" size="small" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="数量">
            <el-input-number v-model="formLabelAlign.num" :precision="2" :max="999999" size="small" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="日期">
            <el-date-picker v-model="formLabelAlign.date" type="date" placeholder="请选择日期" size="small" format="YYYY/MM/DD"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="centerDialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitData()" size="small">
          提交
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import dayjs from 'dayjs';
import { VXETable } from 'vxe-table'
import XEUtils from 'xe-utils'
import { ElMessage } from 'element-plus'
import { ref, nextTick, reactive, onBeforeMount, computed} from 'vue'
import { useStore } from "vuex";
import { getFishInfoList, AddFishSellInfo, GetExcel } from '@/api/fishinfo.js'

const store = useStore();
const toolbarRef = ref()
const tableRef = ref()
const tableData = ref([])
//新增数据变量
const formLabelAlign = reactive({
  type_id: null,
  price: 0.00,
  num: 0,
  date: null,
  userid:null,
})
//弹窗控制变量
const centerDialogVisible = ref(false)
//搜索条件
const searchData = reactive({
  timeNode: '',
  userid: "0",
  fishtype: "0",
})
let typeinfos = ref([])
let users = ref([])


// 格式化日期，默认 yyyy-MM-dd HH:mm:ss
VXETable.formats.add('formatDate', {
  cellFormatMethod ({ cellValue }, format) {
    return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd')
  }
})

/* const footerData = ref([
  ['合计', '-', '-', '-', '-','-',tableData.value.total_price]
]) */
const footerMethod = ({ columns, data }) => {
  // 返回一个二维数组的表尾合计
  let totalNum = 0;
  let totalreward = 0.00;
  let totalPrice = 0.00;
  data.forEach(item => {
    totalNum += parseFloat(item.num);
    totalreward += parseFloat(item.reward);
    totalPrice += item.totalprice;
  });
  return [
    columns.map((column, _columnIndex) => {
      if (_columnIndex === 0) {
        return '合计'
      }
      if (column.property === 'num') {
        return '数量:' + totalNum.toFixed(2);
      }
      if (column.property === 'reward') {
        return '提成:' + totalreward.toFixed(2);
      }
      if (column.property === 'totalprice') {
        return '总价:' + totalPrice.toFixed(2)+ ' ¥';
      }
      return '';
    })
  ];
}
const today = dayjs().format('YYYY-MM-DD');
const DownloadExcel = async () => {
  const response = await GetExcel(searchData);
  const blob = new Blob([response]);

  const filename = `fishExcel_${today}.xlsx`;
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}


//分页变量
const pageVO2 = reactive({
  currentPage: 1,
  pageSize: 20,
  total: 8
})

//提交函数
const submitData = () => {

  console.log(formLabelAlign);
  AddFishSellInfo(formLabelAlign).then(res => {
    console.log(res);
    if (res.code !== 200) {
      ElMessage.error(res.msg)
    } else {
      SeachDataHandle()
      ElMessage({
        message: res.msg,
        type: 'success',
      })
      closeLogDialog()
    }
  })

}
//查询函数
const SeachDataHandle = (page) => {
  page = pageVO2.currentPage
  getFishInfoList(page, searchData).then(res => {
    tableData.value = res.data.data
    pageVO2.currentPage = res.data.current_page
    pageVO2.total = res.data.total
  })
}
const closeLogDialog = () => {
  centerDialogVisible.value = false
}
//分页变化
const pagechangeHandle = (type, currentPage) => {
  SeachDataHandle(currentPage);
}
onBeforeMount(async () => {


  if (Object.keys(store.state.user.FishTypeList).length ==0 || Object.keys(store.state.user.UserList).length ==0) {
    await store.dispatch('user/getFishType')
    await store.dispatch('user/getUserList')
  }
  typeinfos = computed(() => store.state.user.FishTypeList)

  users = computed(() => store.state.user.UserList)

  getFishInfoList(1).then(res => {
    tableData.value = res.data.data
    pageVO2.currentPage = res.data.current_page
    pageVO2.total = res.data.total
  })
})


// 打印样式
// eslint-disable-next-line no-unused-vars
const printStyle = `
.title {
  text-align: center;
}
.my-list-row {
  display: inline-block;
  width: 100%;
}
.my-list-col {
  float: left;
  width: 33.33%;
  height: 28px;
  line-height: 28px;
}
.my-top,
.my-bottom {
  font-size: 12px;
}
.my-top {
  margin-bottom: 5px;
}
.my-bottom {
  margin-top: 30px;
  text-align: right;
}
`
// 打印顶部内容模板
// eslint-disable-next-line no-unused-vars
const topHtml = `
<h1 class="title">出货单据</h1>
<div class="my-top">
  <div class="my-list-row">
    <div class="my-list-col">商品名称：vxe-table</div>
    <div class="my-list-col">发货单号：X2665847132654</div>
    <div class="my-list-col">发货日期：2020-09-20</div>
  </div>
  <div class="my-list-row">
    <div class="my-list-col">收货姓名：小徐</div>
    <div class="my-list-col">收货地址：火星第七区18号001</div>
    <div class="my-list-col">联系电话：10086</div>
  </div>
</div>
`
// 打印底部内容模板
// eslint-disable-next-line no-unused-vars
const bottomHtml = `
<div class="my-bottom">
  <div class="my-list-row">
    <div class="my-list-col"></div>
    <div class="my-list-col">创建人：小徐</div>
    <div class="my-list-col">创建日期：2020-09-20</div>
  </div>
</div>
`

// eslint-disable-next-line no-unused-vars
const printEvent = () => {
  const $table = tableRef.value
  if ($table) {
    $table.print({
      sheetName: '打印出货单据',
      // 打印样式设置
      style: printStyle,
      // 打印指定列
      columns: [
        { type: 'seq' },
        { field: 'name' },
        { field: 'role' },
        { field: 'address' }
      ],
      // 打印之前拼接自定义模板
      beforePrintMethod: ({ content }) => {
        // 打印之前，返回自定义的 html 内容
        return topHtml + content + bottomHtml
      }
    })
  }
}
// eslint-disable-next-line no-unused-vars
const printSelectEvent = () => {
  const $table = tableRef.value
  if ($table) {
    $table.print({
      sheetName: '打印勾选行',
      // 打印样式设置
      style: printStyle,
      mode: 'selected',
      // 打印指定列
      columns: [
        { type: 'seq' },
        { field: 'name' },
        { field: 'role' },
        { field: 'address' }
      ],
      beforePrintMethod: ({ content }) => {
        // 打印之前，返回自定义的 html 内容
        return topHtml + content + bottomHtml
      }
    })
  }
}
nextTick(() => {
  // 将表格和工具栏进行关联
  const $table = tableRef.value
  const $toolbar = toolbarRef.value
  if ($table && $toolbar) {
    $table.connect($toolbar)
  }
})
</script>

<style>
.vxe-footer--row {
  color: red;
}
</style>